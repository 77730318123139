import { defineStore } from '@/shared/states';
import {
  checkBrowserCompatibility,
  detectBrowser
} from '@/shared/utils/browser';

class BrowserStore {
  isSupportedBrowser = checkBrowserCompatibility();

  // 之後有些功能需要特別針對 safari or 非電腦裝置做一些處理時是會用到
  browserInfo = detectBrowser();

  // user 是否允許 website 可以存取其 camera/mic
  hasAccessPermission = true;
}

export const [browserStore, useBrowserStore] = defineStore(
  'BrowserStore',
  new BrowserStore()
);
