import axios from 'axios';
import { showMessage } from './message';

export async function fetchConfig() {
  try {
    const { data } = await axios.get(`${process.env.PUBLIC_URL}/config.json`);
    return data;
  } catch (error) {
    showMessage({
      type: 'error',
      content: '讀取設定檔案失敗'
    });
  }
}
