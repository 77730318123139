import units from './units';

export default class CSSUnitValue {
  #value;
  #unit;

  constructor(...args) {
    if (args.length < 2) {
      throw new TypeError(
        `Failed to construct 'CSSUnitValue': 2 arguments required, but only ${args.length} present.`
      );
    }

    const [value, unit] = args;
    this.#value = getFiniteNumber(value);
    this.#unit = getUnit(unit);
  }

  get value() {
    return this.#value;
  }

  set value(newValue) {
    this.#value = getFiniteNumber(newValue);
  }

  get unit() {
    return this.#unit;
  }

  toString() {
    return `${this.value}${units[this.unit]}`;
  }
}

Object.defineProperties(CSSUnitValue.prototype, {
  value: { enumerable: true },
  unit: { enumerable: true }
});

function getFiniteNumber(value) {
  if (isNaN(value) || Math.abs(value) === Infinity) {
    throw new TypeError(
      `Failed to set the 'value' property on 'CSSUnitValue': The provided double value is non-finite.`
    );
  }

  return Number(value);
}

function getUnit(unit) {
  if (!Object.keys(units).includes(unit)) {
    throw new TypeError(
      `Failed to construct 'CSSUnitValue': Invalid unit: ${unit}`
    );
  }

  return unit;
}
