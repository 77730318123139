import { StreamingSdk } from 'meshstreamSdk';

import { StreamingSdkNotify } from '@/shared/utils';

class PublisherSdk {
  /** @type {string | null} */
  // streamingServerUrl = process.env.REACT_APP_STREAMING_SERVER_API_URL;
  streamingSdk = null;
  joinStatus = false;

  async init(streamingServerUrl) {
    if (streamingServerUrl === null) return;

    const peerId = Math.random().toString(36).substr(2, 8);

    const streamingSdk = new StreamingSdk({
      peerId,
      serverUrl: streamingServerUrl
    });

    streamingSdk
      .on('publishingStream', ({ streamName }) => {
        console.log('收到 publishingStreamName', streamName);
      })
      .on('streamEnded', async ({ streamName }) => {
        console.log('streamEnded', streamName);
      })
      .on('joinEvent:error', ({ error }) => {
        console.error('joinEvent:error', error);
      })
      .on('joinInfo', async data => {
        console.log('joinInfo', data);
        this.joinStatus = true;
      })
      .on('disconnect', ({ reason }) => {
        StreamingSdkNotify.disconnect(reason);
      })
      .on('reconnect', () => {
        StreamingSdkNotify.reconnect();
      })
      .on('reconnect_failed', () => {
        StreamingSdkNotify.reconnectFailed();
      });

    this.streamingSdk = streamingSdk;
    await this.joinEvent();
  }

  async joinEvent() {
    const eventId = '3517148042';
    try {
      await this.streamingSdk.joinEvent({ eventId });
    } catch (error) {
      console.error('streamingSdk.joinEvent() Error', error);
    }
  }
}

export default PublisherSdk;
