import { ref } from 'valtio';
import { defineStore } from '@/shared/states';

class StreamStore {
  // 從 sdk 來，只要正在推的 streamName 都在這，不管是 mixer、guest、local stream
  publishingStreamNames = {};

  localStreams = {};
  subscribedStreams = {};

  initPublishingStreamNames(activeStreamNames) {
    this.publishingStreamNames = activeStreamNames.reduce(
      (result, streamName) => {
        result[streamName] = true;
        return result;
      },
      {}
    );
  }

  async publishStream({
    streamingSdk,
    streamName,
    mediaStream,
    listenStats = false,
    remark
  }) {
    const { success, error } = await streamingSdk.publish({
      type: 'rtc',
      streamName,
      options: {
        videoBitrateMbps: 15
      },
      mediaStream,
      listenStats,
      remark
    });
    console.log(success, streamName);
    if (success) {
      this.localStreams[streamName] = ref(mediaStream);
    }

    return { success, error };
  }

  async unpublishStream({ streamingSdk, streamName }) {
    const { success, error } = await streamingSdk.unpublish({ streamName });
    this.localStreams[streamName] = null;
    return { success, error };
  }

  async subscribeStream({
    streamingSdk,
    streamName,
    listenStats = false,
    remark
  }) {
    const { _success, _error, mediaStream } = await streamingSdk.subscribe({
      streamName,
      listenStats,
      remark
    });

    if (mediaStream) {
      this.subscribedStreams[streamName] = ref(mediaStream);
    }
    console.log('subscribeStream', { streamName, mediaStream });
    return mediaStream;
  }

  async unsubscribeStream({ streamingSdk, streamName }) {
    if (!this.subscribedStreams[streamName]) return;

    const { success, error } = await streamingSdk.unsubscribe({ streamName });
    console.log('unsubscribeStream', { streamName, success, error });
    this.subscribedStreams[streamName] = null;
  }

  resetStreamStore() {
    this.publishingStreamNames = {};
    this.localStreams = {};
    this.subscribedStreams = {};
  }
}

export const [streamStore, useStreamStore] = defineStore(
  'StreamStore',
  new StreamStore()
);
