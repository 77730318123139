import Bowser from 'bowser';
import { detectDevice } from 'mediasoup-client';

export function checkBrowserCompatibility() {
  let isSupportedBrowser = true;
  let isWebrtcAvailable = true;

  if (detectDevice() === undefined) {
    isSupportedBrowser = false;
  } else if (
    navigator.mediaDevices === undefined ||
    navigator.mediaDevices.getUserMedia === undefined ||
    window.RTCPeerConnection === undefined
  ) {
    isWebrtcAvailable = false;
  } else if (!detectBrowser().isValidBrowser) {
    isSupportedBrowser = false;
  }

  // 先統一return false，之後再看要不要細拆 (!isSupportedBrowser & !isWebrtcAvailable 個別顯示不同 UI)
  return isSupportedBrowser && isWebrtcAvailable;
}

export function detectBrowser() {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isValidBrowser = browser.satisfies({
    windows: {
      'internet explorer': '>12',
      'microsoft edge': '>18'
    },
    safari: '>12',
    firefox: '>=60',
    chrome: '>=74',
    opera: '>=62',
    'samsung internet for android': '>=11.1.1.52',
    edge: '>18'
  });

  let flag;

  if (browser.satisfies({ chrome: '>=0', chromium: '>=0' })) flag = 'chrome';
  else if (browser.satisfies({ firefox: '>=0' })) flag = 'firefox';
  else if (browser.satisfies({ safari: '>=0' })) flag = 'safari';
  else if (browser.satisfies({ opera: '>=0' })) flag = 'opera';
  else if (browser.satisfies({ 'microsoft edge': '>=0' })) flag = 'edge';
  else flag = 'unknown';

  return {
    isValidBrowser,
    flag,
    os: browser.getOSName(true), // ios, android, linux...
    platform: browser.getPlatformType(true), // mobile, desktop, tablet
    name: browser.getBrowserName(true),
    version: browser.getBrowserVersion(),
    browser: browser
  };
}
