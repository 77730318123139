import { customAlphabet } from 'nanoid';

/**
 * @typedef {{
 *   size: number;
 *   generate(): string;
 * }} IdGenerator
 */

/** @implements IdGenerator */
class NanoIdGeneratorAdapter {
  /** @type {() => string} */
  #generator;

  /** @param {number} [size] */
  constructor(size) {
    this.size = size;
    const alphanumeric =
      '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    this.#generator = customAlphabet(alphanumeric, size);
  }

  /** @returns {string} */
  generate() {
    return this.#generator();
  }
}

/** @implements IdGenerator */
export class SimpleIdGenerator {
  /** @type {IdGenerator} */
  #generator;

  /** @param {number} [size] */
  constructor(size) {
    this.#generator = new NanoIdGeneratorAdapter(size);
    this.size = size;
  }

  /** @returns {string} */
  generate() {
    return this.#generator.generate();
  }
}

/** @implements IdGenerator */
export class FrontendTempIdGenerator {
  #prefix = `frontend_temp_`;

  /** @type {IdGenerator} */
  #generator;

  /**
   * @param {IdGenerator} generator
   */
  constructor(generator) {
    this.#generator = generator;
    this.size = generator.size;
  }

  /** @returns {string} */
  generate() {
    const id = this.#generator.generate();
    return `${this.#prefix}${id}`;
  }

  /**
   * 驗證是否為 frontend temp ID
   * @param {string} id - 要驗證的 ID
   * @returns {boolean}
   */
  validate(id) {
    return (
      id.startsWith(this.#prefix) &&
      id.length - this.#prefix.length === this.size
    );
  }
}

export const frontendTempIdGenerator = new FrontendTempIdGenerator(
  new SimpleIdGenerator(6)
);
