const units = {
  number: '',
  percent: '%',
  em: 'em',
  ex: 'ex',
  ch: 'ch',
  rem: 'rem',
  vw: 'vw',
  vh: 'vh',
  vmin: 'vmin',
  vmax: 'vmax',
  cm: 'cm',
  mm: 'mm',
  in: 'in',
  pt: 'pt',
  pc: 'pc',
  px: 'px',
  Q: 'Q',
  deg: 'deg',
  grad: 'grad',
  rad: 'rad',
  turn: 'turn',
  s: 's',
  ms: 'ms',
  Hz: 'Hz',
  kHz: 'kHz',
  dpi: 'dpi',
  dpcm: 'dpcm',
  dppx: 'dppx',
  fr: 'fr'
};

export default units;
