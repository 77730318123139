// import { useState } from 'react';
import { ConfigProvider, _Result } from 'antd';
import zhTW from 'antd/es/locale/zh_TW';
// import { backendApi } from './api';
// import { LoadingSpin } from './shared/components';
import { useMounted } from './shared/hooks';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-tw';

import Routes from '@/routes';

import './App.scss';

dayjs.locale('zh-tw');

function App() {
  // const [streamingServerInfo, setStreamingServerInfo] = useState(null);

  useMounted(() => {
    // healthCheck();
  });

  // async function healthCheck() {
  //   const data = await backendApi.health.healthCheck();
  //   setStreamingServerInfo(() => data);
  // }

  // if (streamingServerInfo === null) return <LoadingSpin />;
  // if (!streamingServerInfo.healthy)
  //   return <Result status="500" title="找不到對應的伺服器" />;

  return (
    <ConfigProvider locale={zhTW}>
      <div className="App">
        <Routes />
      </div>
    </ConfigProvider>
  );
}

export default App;
