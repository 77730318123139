import { notification, Spin } from 'antd';

import { frontendTempIdGenerator } from '@/shared/utils';

/** @typedef {import('react')} React */

/** @typedef {React.ReactNode} NotifyMessage */

/**
 * @typedef {
 *   | 'top'
 *   | 'topLeft'
 *   | 'topRight'
 *   | 'bottom'
 *   | 'bottomLeft'
 *   | 'bottomRight'
 * } NotifyPlacement
 */

/**
 * @typedef {{
 *   key?: string;
 *   description?: React.ReactNode;
 *   duration?: number | null;
 *   top: number;
 *   placement?: NotifyPlacement;
 *   style?: React.CSSProperties;
 *   className?: string;
 *   onClose?: () => void;
 *   onClick?: () => void;
 *   icon?: React.ReactNode;
 *   closeIcon?: React.ReactNode;
 *   btn?: React.ReactNode;
 *   props?: object;
 * }} NotifyOptions
 */

const defaultOptions = {
  top: 14
};

export const Notify = {
  /**
   * @param {NotifyMessage} message
   * @param {NotifyOptions} options
   */
  loading(message, options = {}) {
    const notificationKey = frontendTempIdGenerator.generate();
    notify();

    function notify() {
      notification.open({
        key: notificationKey,
        message,
        icon: <Spin />,
        duration: options.duration ?? 0,
        ...defaultOptions,
        ...options
      });
    }
    function close() {
      notification.close(notificationKey);
    }
    return close;
  },

  /**
   * @param {NotifyMessage} message
   * @param {NotifyOptions} options
   */
  success(message, options = {}) {
    notification.success({
      message,
      duration: options.duration ?? 0,
      ...defaultOptions,
      ...options
    });
  },

  /**
   * @param {NotifyMessage} message
   * @param {NotifyOptions} options
   */
  error(message, options = {}) {
    notification.error({
      message,
      duration: options.duration ?? 0,
      ...defaultOptions,
      ...options
    });
  },

  /**
   * @param {NotifyMessage} message
   * @param {NotifyOptions} options
   */
  info(message, options = {}) {
    notification.info({
      message,
      duration: options.duration ?? 0,
      ...defaultOptions,
      ...options
    });
  },

  /** @param {string} key */
  close(key) {
    notification.close(key);
  }
};

export const StreamingSdkNotify = {
  /** @param {string} reason */
  disconnect(reason) {
    const notifyKey = 'disconnect';

    Notify.close(notifyKey);

    Notify.error('streaming server 斷線', {
      key: notifyKey,
      description: reason
    });
  },
  reconnect() {
    const notifyKey = 'reconnect';

    Notify.close(notifyKey);

    Notify.info('streaming server 重新連線', {
      key: notifyKey
    });
  },
  reconnectFailed() {
    const notifyKey = 'reconnect_failed';

    Notify.close(notifyKey);

    Notify.error('streaming server 重新連線失敗', {
      key: notifyKey
    });
  },
  recordingFailed() {
    const notifyKey = 'recording_failed';

    Notify.close(notifyKey);

    Notify.error('錄影發生錯誤', {
      key: notifyKey
    });
  },
  pushingToRtmpFailed() {
    const notifyKey = 'pushingToRtmp_failed';

    Notify.close(notifyKey);

    Notify.error('推送串流平台發生錯誤', {
      key: notifyKey
    });
  }
};
