import { addIcon } from '@iconify/react/offline';

import twotonePlus from '@iconify-icons/ic/twotone-plus';

addIcon('ic:twotone-plus', twotonePlus);

/**
 * @example
 * import { Icon } from '@/shared/components';
 *
 * <Icon icon="ic:twotone-plus" />
 */
