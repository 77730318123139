import { useEffect } from 'react';

export function useMounted(callback, unmounted = () => {}) {
  useEffect(() => {
    (async () => {
      await callback();
    })();

    return () => {
      unmounted();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
