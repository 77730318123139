import { Navigate, useRoutes } from 'react-router-dom';
import { Publisher } from '@/modules/Publisher';
import { Subscriber } from '@/modules/Subscriber';

export default function Routes() {
  const routes = [
    { path: 'publisher', element: <Publisher /> },
    { path: 'subscriber', element: <Subscriber /> },
    { path: '*', element: <Navigate to="/publisher" /> }
  ];
  return useRoutes(routes);
}
