import { browserStore } from '@/shared/states';

const audioConstraints = {
  echoCancellation: true,
  noiseSuppression: true,
  voiceActivatedUnmute: false,
  noiseThreshold: -60,
  sampleRate: 48000
};

export async function askUserMediaPermission() {
  /*
    初次載入頁面時，先詢問使用者相機、麥克風權限
    才能 enumDevices
  */

  const { state: cameraState } = await navigator.permissions.query({
    name: 'camera'
  });

  const { state: micState } = await navigator.permissions.query({
    name: 'microphone'
  });

  if (cameraState === 'granted' && micState === 'granted') {
    // 已授權
    return;
  } else {
    const cameraStream = await getCameraStream();
    const micStream = await getMicStream();
    stopStream(cameraStream);
    stopStream(micStream);
  }
}

export async function getCameraStream(videoConstraints) {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: videoConstraints ? videoConstraints : true
    });
    return stream;
  } catch (error) {
    _handleGetStreamError(error);
  }
}

export async function getMicStream(audioInputDeviceId) {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        ...audioConstraints,
        deviceId: audioInputDeviceId ? { ideal: audioInputDeviceId } : undefined
      }
    });
    return stream;
  } catch (error) {
    _handleGetStreamError(error);
  }
}

export async function getScreenStream(videoConstraints) {
  try {
    const stream = await navigator.mediaDevices.getDisplayMedia({
      audio: true,
      video: {
        ...videoConstraints,
        cursor: 'never'
      }
    });
    return stream;
  } catch (error) {
    _handleGetStreamError(error, true);
  }
}

export async function applyVideoConstraints({ mediaStream, constraints }) {
  const videoTrack = mediaStream.getVideoTracks()[0];
  await videoTrack.applyConstraints(constraints);
}

export function stopStream(mediaStream) {
  if (!mediaStream) return;

  const tracks = mediaStream.getTracks();
  tracks.map(track => track.stop());
}

export async function enumDevices() {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();

    return devices.reduce(
      (result, device) => {
        const deviceListByKind = {
          videoinput: result.videoInputs,
          audioinput: result.audioInputs,
          audiooutput: result.audioOutputs
        };

        const { kind, deviceId, groupId, label } = device;

        const deviceList = deviceListByKind[kind];
        deviceList?.push({
          kind,
          deviceId,
          groupId,
          label: label || '預設',
          key: deviceId
        });
        return result;
      },
      {
        videoInputs: [],
        audioInputs: [],
        audioOutputs: []
      }
    );
  } catch (error) {
    alert(`UpdateWebcamDevices error: ${error}`);
  }
}

function _handleGetStreamError(error, isScreenShare) {
  console.log(error.name);
  if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
    //required track is missing
    console.log('DevicesNotFoundError');
  } else if (
    error.name === 'NotReadableError' ||
    error.name === 'TrackStartError'
  ) {
    //webcam or mic are already in use
    alert('NotReadableError');
  } else if (
    error.name === 'OverconstrainedError' ||
    error.name === 'ConstraintNotSatisfiedError'
  ) {
    //constraints can not be satisfied by avb. devices
    alert('OverconstrainedError');
  } else if (
    error.name === 'NotAllowedError' ||
    error.name === 'PermissionDeniedError'
  ) {
    //permission denied in browser

    if (isScreenShare) return;
    browserStore.hasAccessPermission = false;
  } else if (error.name === 'TypeError' || error.name === 'TypeError') {
    //empty constraints object
    alert('TypeError');
  } else {
    //other errors
    alert('GetStream Error');
  }
}
