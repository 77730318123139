import { StreamingSdk } from 'meshstreamSdk';
import { streamStore } from '@/shared/states';
import { StreamingSdkNotify } from '@/shared/utils';

class SubscriberSdk {
  /** @type {string | null} */
  // streamingServerUrl = process.env.REACT_APP_STREAMING_SERVER_API_URL;
  streamingSdk = null;
  joinStatus = false;
  remark = {
    elkDisplayName: `HTC - left`
  }; // elk 數據，確定一次性的 demo 只有一個 stream 在推，三個 subscriber 分別為left、middle、right(看 user 選哪個而定)

  async init(streamingServerUrl) {
    if (streamingServerUrl === null) return;

    const peerId = Math.random().toString(36).substr(2, 8);

    const streamingSdk = new StreamingSdk({
      peerId,
      serverUrl: streamingServerUrl
    });

    streamingSdk
      .on('publishingStream', ({ streamName }) => {
        console.log('收到 publishingStreamName', streamName);

        if (streamName === 'immersive') {
          streamStore.subscribeStream({
            streamName,
            streamingSdk,
            listenStats: true,
            remark: this.remark
          });
          streamStore.publishingStreamNames[streamName] = true;
        }
      })
      .on('streamEnded', async ({ streamName }) => {
        // const { scene } = getState();
        console.log('streamEnded', streamName);

        if (streamName === 'immersive') {
          streamStore.unsubscribeStream({ streamName, streamingSdk });
          streamStore.publishingStreamNames[streamName] = false;
        }
      })
      .on('joinEvent:error', ({ error }) => {
        console.error('joinEvent:error', error);
      })
      .on('joinInfo', async data => {
        console.log('joinEvent');
        console.log('joinInfo', data);
        this.joinStatus = true;

        streamStore.initPublishingStreamNames(data.activeStreamNames);
        const targetStreamName = data.activeStreamNames.find(
          streamName => streamName === 'immersive'
        );

        console.log('targetStreamName', data.activeStreamNames);

        if (targetStreamName) {
          streamStore.subscribeStream({
            streamName: 'immersive',
            streamingSdk,
            listenStats: true,
            remark: this.remark
          });
        }
      })
      .on('disconnect', ({ reason }) => {
        StreamingSdkNotify.disconnect(reason);
      })
      .on('reconnect', () => {
        StreamingSdkNotify.reconnect();
      })
      .on('reconnect_failed', () => {
        StreamingSdkNotify.reconnectFailed();
      });

    this.streamingSdk = streamingSdk;
    await this.joinEvent();
  }

  async joinEvent() {
    const eventId = '3517148042';
    try {
      await this.streamingSdk.joinEvent({ eventId });
    } catch (error) {
      console.error('streamingSdk.joinEvent() Error', error);
    }
  }

  updateRemarkUserName(part) {
    /**
     * elk 數據，確定為一次性的 demo 只有會 publish 一個螢幕分享 stream
     * 三個 subscriber 的 userName 根據 user 選擇觀看 stream 的left、middle、right而定
     *  */
    this.remark = {
      elkDisplayName: `HTC - ${part}`
    };
  }
}

export default SubscriberSdk;
