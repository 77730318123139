import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';

import { devtools } from './';

/**
 * @template T
 * @param {string} name
 * @param {T} initialObject
 * @returns {[T, () => T]}
 */
export function defineStore(name, initialObject) {
  const store = proxy(initialObject);

  // 啟用 Redux DevTools Extension 來 debug Valtio
  devtools(store, {
    name,
    enabled: true
  });

  /** @returns {T} */
  function useStore() {
    return useProxy(store);
  }

  return [store, useStore];
}
