export class HttpResponseError extends Error {
  status = null;

  constructor(message, { cause = null }) {
    super(message);

    this.name = this.constructor.name;
    this.message = message;
    this.cause = cause;
  }
}
