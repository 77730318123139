import units from './units';
import CSSUnitValue from './CSSUnitValue';

export default class CSS {}

Object.keys(units).forEach(unit => {
  if (!(unit in CSS)) {
    CSS[unit] = value => new CSSUnitValue(value, unit);
  }
});
