import units from './units';
import CSSUnitValue from './CSSUnitValue';
import CSSKeywordValue from './CSSKeywordValue';

const unitKeys = Object.keys(units);
const unitValues = Object.values(units);
const unitParsingMatcher = new RegExp(
  // eslint-disable-next-line no-useless-escape
  `^([-+]?[0-9]*\.?[0-9]+)(${unitValues.join('|')})?$`
);

export default class CSSNumericValue {
  static parse(string) {
    const unitParsingMatch = String(string).match(unitParsingMatcher);

    if (unitParsingMatch) {
      const [, value, unit] = unitParsingMatch;
      return new CSSUnitValue(value, unitKeys[unitValues.indexOf(unit || '')]);
    }
    return new CSSKeywordValue(string);
  }
}
