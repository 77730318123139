import { useState } from 'react';
import { useMounted } from '@/shared/hooks';
import { Segmented } from 'antd';
import ReactPlayer from 'react-player';
import { fetchConfig } from '@/shared/utils';
import { useStreamStore } from '@/shared/states';
import { SubscriberSdk } from '../../utils';
import './Subscriber.scss';

const subscriber = new SubscriberSdk();
const streamName = 'immersive';

function Subscriber() {
  const [selectedPart, setSelectedPart] = useState('left');
  const [isShowHeader, setIsShowHeader] = useState(true);

  const streamStore = useStreamStore();

  useMounted(() => {
    mounted();
  });

  async function mounted() {
    if (!subscriber.joinStatus) {
      const { subscriberStreamingServerUrl } = await fetchConfig();
      await subscriber.init(subscriberStreamingServerUrl);
    }
  }

  async function changePart(value) {
    subscriber.updateRemarkUserName(value);
    setSelectedPart(value);

    if (!streamStore.publishingStreamNames[streamName]) return;

    await streamStore.unsubscribeStream({
      streamName,
      streamingSdk: subscriber.streamingSdk
    });

    streamStore.subscribeStream({
      streamName: 'immersive',
      streamingSdk: subscriber.streamingSdk,
      listenStats: true,
      remark: subscriber.remark
    });
  }

  return (
    <div onDoubleClick={() => setIsShowHeader(() => !isShowHeader)}>
      <If condition={isShowHeader}>
        <header className="header">
          <Segmented
            options={['left', 'middle', 'right']}
            onChange={changePart}
          />
        </header>
      </If>
      <main className="relative h-screen overflow-hidden">
        <div className={`video-container ${selectedPart}`}>
          <If condition={streamStore.publishingStreamNames[streamName]}>
            <ReactPlayer
              url={streamStore.subscribedStreams[streamName]}
              // 寬高設定參考 README.md 的 flow 圖
              width="300vw"
              height="100vh"
              playing={true}
              playsinline={true}
              muted={true}
            />
          </If>
        </div>
      </main>
    </div>
  );
}

export default Subscriber;
