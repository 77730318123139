export default class CSSKeywordValue {
  #value;

  constructor(...args) {
    if (args.length < 1) {
      throw new TypeError(
        `Failed to construct 'CSSKeywordValue': 1 arguments required, but only ${args.length} present.`
      );
    }

    const [value] = args;

    this.#value = String(value);
  }

  get value() {
    return this.#value;
  }

  set value(newValue) {
    this.#value = String(newValue);
  }

  toString() {
    return this.value;
  }
}

Object.defineProperties(CSSKeywordValue.prototype, {
  value: { enumerable: true }
});
