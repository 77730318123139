import { useState } from 'react';
import { Button } from 'antd';
import ReactPlayer from 'react-player';
import { getScreenStream, showMessage, fetchConfig } from '@/shared/utils';
import { PublisherSdk } from '../../utils';
import { useMounted } from '@/shared/hooks';
import { useStreamStore } from '@/shared/states';
import './Publisher.view.scss';

const publisher = new PublisherSdk();
const streamName = 'immersive';

function Publisher() {
  const [localStream, setLocalStream] = useState(null);
  const streamStore = useStreamStore();

  useMounted(() => {
    mounted();
  });

  async function mounted() {
    if (!publisher.joinStatus) {
      const { publisherStreamingServerUrl } = await fetchConfig();
      await publisher.init(publisherStreamingServerUrl);
    }
  }

  async function shareScreen() {
    /**
     * 自己開發要測的話用 1920*1080，HTC 要測的話用 5760*1200
     * 因為我們一般的螢幕推不了那麼高的解析度
     */
    // const videoConstraints = { width: 1920, height: 1080, frameRate: 60 };
    const videoConstraints = { width: 5760, height: 1200, frameRate: 60 };

    const mediaStream = await getScreenStream(videoConstraints);
    const { success, error } = await streamStore.publishStream({
      streamingSdk: publisher.streamingSdk,
      streamName,
      mediaStream,
      listenStats: true,
      remark: {
        elkDisplayName: `HTC`
      }
    });

    if (success) {
      setLocalStream(() => mediaStream);
      showMessage({
        type: 'info',
        content: '已成功推送螢幕畫面'
      });
    }
    if (error) {
      showMessage({
        type: 'error',
        content: '無法推送螢幕畫面'
      });
    }
  }

  async function stopShareScreen() {
    const { success, error } = await streamStore.unpublishStream({
      streamingSdk: publisher.streamingSdk,
      streamName
    });

    setLocalStream(() => null);

    if (success) {
      showMessage({
        type: 'info',
        content: '已取消推送螢幕畫面'
      });
    }

    if (error) {
      showMessage({
        type: 'error',
        content: '無法取消推送螢幕畫面'
      });
    }
  }

  return (
    <div className="relative">
      <header>
        <Choose>
          <When condition={!localStream}>
            <Button type="primary" onClick={shareScreen}>
              分享螢幕
            </Button>
          </When>
          <Otherwise>
            <Button type="primary" onClick={stopShareScreen}>
              停止分享螢幕
            </Button>
          </Otherwise>
        </Choose>
      </header>
      <main>
        <div className="video-container">
          <ReactPlayer
            url={localStream}
            width="100%"
            height="100%"
            playing={localStream ? true : false}
            playsinline={true}
            controls
          />
        </div>
      </main>
    </div>
  );
}

export default Publisher;
